import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { TextVariant } from '@naf/text';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { useCloudinary } from '../hooks/Cloudinary/useCloudinary';
import useSelector from '../redux/typedHooks';
import { useWindowLocation } from '../hooks/Window/useWindowLocation';
import { actions as articleActions } from '../redux/modules/articles';
import S from '../styles/articles/StyledArticle';
import FeedbackSurvey from '../components/feedbacksurvey/FeedbackSurvey';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import { LayoutWithMainContent } from '../components/layout/Layout';
import SubCategoryChips from '../components/subcategories/SubCategoryChips';
import { SiteStructureType } from '../../../types/siteStructureType';
import ArticleGrid from '../components/article-grid/ArticleGrid';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';
import InfinteScroll from '../components/infinite-scroll/InfinteScroll';
import { NotFound } from '../loadable-elements/NotFound';
import { useAuth0Token } from '../hooks/useAuth0Token';

interface Props {
  category: SiteStructureType;
}

// NB! This page should only be referenced through PageHandler
const ArticleList = ({ category }: Props) => {
  const appInsights = useAppInsightsContext();
  const cld = useCloudinary();
  const windowPath = useWindowLocation();
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();
  const [initialRequestMadeWithoutToken, setInitialRequestMadeWithoutToken] = useState(false);

  useTrackMetric(appInsights, 'Artikkel-liste');

  const { total, totalFetchedArticles, isLoadingMore, pages, categories, errorState } =
    useSelector((state) => state.articles.data[category.slug]) || {};

  const onShowMoreBtnClick = category
    ? () => {
        dispatch(articleActions.getMoreArticles.request({ category, simpleToken }));
      }
    : null;

  useEffect(() => {
    if (!pages || Object.values(pages).length === 0) {
      dispatch(articleActions.getArticleList.request({ category, simpleToken }));

      if (!simpleToken) {
        setInitialRequestMadeWithoutToken(true);
      }
    }
  }, [pages, category, dispatch, simpleToken]);

  useEffect(() => {
    if (initialRequestMadeWithoutToken && simpleToken) {
      // Token is not always ready on first render, so we need to check if it is available.
      dispatch(articleActions.getArticleList.request({ category, simpleToken }));
      setInitialRequestMadeWithoutToken(false);
    }
  }, [simpleToken, category, dispatch, initialRequestMadeWithoutToken]);

  // If category does not exist in sitestructure, assume it is an invalid page
  if (!category) return <NotFound />;

  const categoryList = categories ? categories.map((subCategory) => subCategory.slug) : null;
  const uniqueCategoryList = [...new Set(categoryList)];
  const pageTitle = category?.seoConfig?.title || category.slug.replace('-', ' ');

  const categoryImage =
    (category?.image &&
      category?.image.publicId &&
      cld
        .image(category?.image.publicId)
        .resize(fill().width(1200).height(630))
        .quality('auto:eco')
        .format('auto')
        .toURL()) ||
    undefined;

  return (
    <LayoutWithMainContent
      title={category.name || pageTitle}
      description={category?.ingress || category?.seoConfig?.introduction}
      url={windowPath}
      imgUrl={categoryImage || ''}
      gtmArgs={{
        page_type: 'ArticleListPage',
        pageCategory: category?.url || '',
        contentId: category?._id || '',
      }}
      seoTitle={pageTitle}
      seoDescription={category?.seoConfig?.introduction}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.CategoryApp,
        imagePublicId: category?.image?.publicId,
        tags: [],
      }}
    >
      <S.Wrapper>
        <Grid>
          <GridCol s="12" m="12" l="12" xl="12">
            <BreadCrumb />
          </GridCol>
          <GridCol s="12" m="12" l="8" xl="8">
            <S.CategoryTitle tag="h1" variant={TextVariant.Header1}>
              {category?.name || pageTitle}
            </S.CategoryTitle>
            {category?.ingress && (
              <S.Ingress tag="p" variant={TextVariant.Ingress}>
                {category.ingress}
              </S.Ingress>
            )}
          </GridCol>
          {uniqueCategoryList && (
            <S.StyledGridCol s="12" m="12" l="12" xl="12">
              <SubCategoryChips chips={uniqueCategoryList} currentCategory={category} />
            </S.StyledGridCol>
          )}
          <GridRow>
            {pages && (
              <InfinteScroll
                showLoadingTrigger={!isLoadingMore && totalFetchedArticles < total}
                onTrigger={onShowMoreBtnClick}
              >
                {Object.entries(pages).map(([key, { data, isLoading }]) => (
                  <ArticleGrid
                    key={key}
                    articleItems={data}
                    updating={isLoading}
                    error={typeof errorState !== 'undefined'}
                    showDate
                    showNewsLabel
                  />
                ))}
              </InfinteScroll>
            )}
          </GridRow>
          <GridCol s="12" m="12" l="12" xl="12">
            <p>
              Viser {totalFetchedArticles} av {total}
            </p>
          </GridCol>
          <GridRow>
            <GridCol s="12" m="12" l="8" xl="8">
              <FeedbackSurvey contentUrl={windowPath} />
            </GridCol>
          </GridRow>
        </Grid>
      </S.Wrapper>
    </LayoutWithMainContent>
  );
};

export default ArticleList;
